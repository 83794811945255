import { defineStore } from 'pinia'
import clonedeep from "clone-deep"
import { generateUUID } from "~/lib/utils.js"
import blocksTypes from "~/data/blocks_types.json"
import { useAlert } from '~/composables/useAlert'
import { useCrisp } from '~/composables/useCrisp'

export const useWorkingFormStore = defineStore('working_form', {
  state: () => ({
    content: null,
    activeTab: 0,
    formPageIndex: 0,
    
    // Field being edited
    selectedFieldIndex: null,
    showEditFieldSidebar: null,
    showAddFieldSidebar: null,
    blockForm: null,
    draggingNewBlock: false,
  }),
  actions: {
    set(form) {
      this.content = clonedeep(form)
    },
    setProperties(properties) {
      this.content.properties = clonedeep(properties)
    },
    objectToIndex(field) {
      if (typeof field === 'object') {
        return this.content.properties.findIndex(
          prop => prop.nf_id === field.nf_id
        )
      }
      return field
    },
    setEditingField(field) {
      this.selectedFieldIndex = this.objectToIndex(field)
    },
    openSettingsForField(field) {
      this.setEditingField(field)
      this.showEditFieldSidebar = true
      this.showAddFieldSidebar = false
    },
    hideField(field) {
      const index = this.objectToIndex(field)
      this.content.properties[index].hidden = true
    },
    removeField(field) {
      let fieldObject = field
      if (typeof field === 'number') {
        fieldObject = this.content.properties[field]
      }

      if (this.content.without_database || fieldObject.newly_added) {
        this.internalRemoveField(field)
      } else if (!this.isBlockField(fieldObject)) {
        if (!fieldObject.hidden) {
          useAlert().error('Notion properties can only be removed from Notion. You can hide it instead.', 10000, {
            title: 'Cannot be removed',
            actions: [{
              label: 'Hide Field',
              click: () => {
                this.hideField(field)
              }
            }]
          })
        } else {
          useAlert().error('This field can only be removed from Notion. It\'s already hidden.', 10000, {
            title: 'Cannot be removed',
            actions: [{
              label: 'Learn More',
              icon: 'i-heroicons-question-mark-circle',
              click: () => {
                useCrisp().openHelpdeskArticle('how-do-i-delete-a-field-1ewf3cr')
              }
            }]
          })
        }
      } else {
        this.internalRemoveField(field)
      }
    },
    internalRemoveField(field) {
      const index = this.objectToIndex(field)

      if (index !== -1) {
        useAlert().success('Ctrl + Z to undo',10000,{
          title: 'Field removed',
          actions: [{
            label: 'Undo',
            icon:"i-material-symbols-undo",
            click: () => {
              this.undo()
            }
          }]
        })
        this.content.properties.splice(index, 1)
      }
    },
    isBlockField(field) {
      return field.type && field.type.startsWith('nf-')
    },
    closeEditFieldSidebar() {
      this.selectedFieldIndex = null
      this.showEditFieldSidebar = false
      this.showAddFieldSidebar = false
    },
    openAddFieldSidebar(field) {
      if (field !== null) {
      this.setEditingField(field)
      }
      this.showAddFieldSidebar = true
      this.showEditFieldSidebar = false
    },
    closeAddFieldSidebar() {
      this.selectedFieldIndex = null
      this.showAddFieldSidebar = false
      this.showEditFieldSidebar = false
    },
    reset() {
      this.content = null
      this.selectedFieldIndex = null
      this.showEditFieldSidebar = null
      this.showAddFieldSidebar = null
    },
    resetBlockForm() {
      this.blockForm = useForm({
        type: null,
        name: null,
      })
    },
    prefillDefault(data) {
    
      let id = this.getRandomId()
      while (this.idUsedInForms(id)) {
        id = this.getRandomId()
      }
      data.id = id
      data.nf_id = generateUUID()
      data.hidden = false
      data.help_position = 'below_input'

      // If a field already has this name, we need to make it unique with a number at the end
      let baseName = data.name
      let counter = 1
      while (this.content.properties.some(prop => prop.name === data.name)) {
        counter++
        data.name = `${baseName} ${counter}`
      }

      // Type-specific logic
      if (data.type === 'nf-text') {
        data.content = '<p>This is a text block.</p>'
      }
      else if (data.type === 'nf-page-break') {
        data.next_btn_text = 'Next'
        data.previous_btn_text = 'Previous'
      }
      else if (['nf-page-body-input', 'nf-page-logo', 'nf-page-cover'].includes(data.type)) {
        data.required = false
        data.hidden = false
        data.placeholder = null
        data.prefill = null
        data.with_title = data.type === 'nf-page-body-input'
      }
      else if (data.type === 'nf-code') {
        data.content = '<div class="text-blue-500 italic">This is a code block.</div>'
      }
      else if (['select', 'multi_select', 'status'].includes(data.type)) {
        data[data.type] = { options: [] }
      }

      return data
    },
    /**
     * Generate random string id
     */
    getRandomId() {
      return (
        Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15)
      )
    },
    /**
     * Returns true if this id already exists in the form (makes sur its unique)
     * @param id
     */
    idUsedInForms(id) {
      !this.form?.properties.filter((block) => {
        return block.id === id
      }).length === 0
    },
     /**
     * Adds a new block to the form's properties
     * @param {Object} newBlock - The new block to be added
     * @param {number|null} index - The index at which to insert the new block (optional)
     */
    onAdd(newBlock, index = null, openSettings = true) {
      const newFields = clonedeep(this.content.properties)
      const insertIndex = this.determineInsertIndex(index)

      newFields.splice(insertIndex, 0, newBlock)
      this.content.properties = newFields
      if (openSettings) {
        this.openSettingsForField(insertIndex)
      }
    },
    determineInsertIndex(index) {
      if (index !== null && typeof index === 'number') {
        return index
      }
      if (this.selectedFieldIndex !== null && this.selectedFieldIndex !== undefined) {
        return this.selectedFieldIndex + 1
      }
      return this.content.properties.length
    },
    addBlock(type, index = null, openSettings = true) {
      this.blockForm.type = type
      this.blockForm.name = blocksTypes[type].default_block_name
      const data = this.prefillDefault(this.blockForm.data())
      
      // For native types, we want to add the "newly_added" property to add column to Notion DB
      if (blocksTypes[type]?.is_native_type === true) {
        data.newly_added = true
      }

      this.onAdd(data, index, openSettings)
      this.resetBlockForm()
    },
    moveField(oldIndex, newIndex) {
      const newFields = clonedeep(this.content.properties)
      const field = newFields.splice(oldIndex, 1)[0]
      newFields.splice(newIndex, 0, field)
      this.content.properties = newFields
    }
  },
  history: {}
})
